import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Divider, Button } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import Iconify from '../../../components/Iconify';

//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
// hooks
import useAuth from '../../../hooks/useAuth';
// import axios from '../../../utils/axios'


// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar, notifications }) {
  const { user } = useAuth();
  const theme = useTheme();

  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname, onCloseSidebar]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        background: 'linear-gradient(to bottom, #1c1c1c, #333)',
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.2)', my: 2 }} />

        {user.estado === true ? (
          <NavSectionVertical
            user={user}
            navConfig={navConfig}
            isCollapse={isCollapse}
            notifications={notifications}
          />
        ) : (
          <NavbarDocs />
        )}
      </Stack>

      <Box sx={{ flexGrow: 1 }} />

  
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: '#1c1c1c',
              color: '#fff',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                bgcolor: '#333',
                boxShadow: theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}


// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('lg')]: {
//     flexShrink: 0,
//     transition: theme.transitions.create('width', {
//       duration: theme.transitions.duration.shorter,
//     }),
//   },
// }));

// // ----------------------------------------------------------------------

// NavbarVertical.propTypes = {
//   isOpenSidebar: PropTypes.bool,
//   onCloseSidebar: PropTypes.func,
//   notifications: PropTypes.object
// };

// export default function NavbarVertical({ isOpenSidebar, onCloseSidebar, notifications }) {
//   const { user } = useAuth(); 
//   const theme = useTheme();

//   const { pathname } = useLocation();

//   const isDesktop = useResponsive('up', 'lg');

//   // const [notifications, setNotifications] = useState({});

  

//   const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
//     useCollapseDrawer();

//   useEffect(() => {
//     if (isOpenSidebar) {
//       onCloseSidebar();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [pathname]);

//   // useEffect(() => {
//   //   const fetchNotifications = async () => {
//   //     console.log("cambiax")
//   //     try {
//   //       const response = await axios.get('/api/notificacion/side/bar'); 

//   //       console.log("notificaciones,", response.data)
//   //       setNotifications(response.data);
//   //     } catch (error) {
//   //       console.error("Error fetching notifications", error);
//   //     }
//   //   };

//   //   fetchNotifications();
//   //   // Set the timer to fetch notifications every 60 seconds
//   //   const timerId = setInterval(() => {
//   //     fetchNotifications();
//   //   }, 60000);

//   //   // Clear the timer when the component is unmounted
//   //   return () => {
//   //     clearInterval(timerId);
//   //   };
//   // }, [pathname]);

//   const renderContent = (
//     <Scrollbar
//       sx={{
//         height: 1,
//         '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
//       }}
//     >
//       <Stack
//         spacing={3}
//         sx={{
//           pt: 3,
//           pb: 2,
//           px: 2.5,
//           flexShrink: 0,
//           ...(isCollapse && { alignItems: 'center' }),
//         }}
//       >
//         <Stack direction="row" alignItems="center" justifyContent="space-between">
//           <Logo />

//           {isDesktop && !isCollapse && (
//             <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
//           )}
//         </Stack>

//         {/* <NavbarAccount isCollapse={isCollapse} /> */}
//       </Stack>

//       {user.estado===true? <NavSectionVertical user={user} navConfig={navConfig} isCollapse={isCollapse} notifications={notifications}/>: <NavbarDocs />}
      
//       <Box sx={{ flexGrow: 1 }} />

      
//     </Scrollbar>
//   );

//   return (
//     <RootStyle
//       sx={{
//         width: {
//           lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
//         },
//         ...(collapseClick && {
//           position: 'absolute',
//         }),
//       }}
//     >
//       {!isDesktop && (
//         <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
//           {renderContent}
//         </Drawer>
//       )}

//       {isDesktop && (
//         <Drawer
//           open
//           variant="persistent"
//           onMouseEnter={onHoverEnter}
//           onMouseLeave={onHoverLeave}
//           PaperProps={{
//             sx: {
//               width: NAVBAR.DASHBOARD_WIDTH,
//               borderRightStyle: 'dashed',
//               bgcolor: 'background.default',
//               transition: (theme) =>
//                 theme.transitions.create('width', {
//                   duration: theme.transitions.duration.standard,
//                 }),
//               ...(isCollapse && {
//                 width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
//               }),
//               ...(collapseHover && {
//                 ...cssStyles(theme).bgBlur(),
//                 boxShadow: (theme) => theme.customShadows.z24,
//               }),
//             },
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       )}
//     </RootStyle>
//   );
// }
