// @mui
import { styled } from '@mui/material/styles';
import { Card, Alert, Container, Typography } from '@mui/material';
// routes
// hooks
// import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';

// // ----------------------------------------------------------------------

// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
//   backgroundImage: `url('/logo/ticket.webp')`, 
//   backgroundSize: 'cover' 
// }));

// // const SectionStyle = styled(Card)(({ theme }) => ({
// //   width: '100%',
// //   maxWidth: 464,
// //   display: 'flex',
// //   flexDirection: 'column',
// //   justifyContent: 'center',
// //   margin: theme.spacing(2, 0, 2, 2),
// // }));

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 580,
//   margin: 'auto',
//   display: 'flex',
//   minHeight: '100vh',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(12, 0),
// }));

// // ----------------------------------------------------------------------

// export default function Login() {

//   // const mdUp = useResponsive('up', 'md');

//   return (
//     <Page title="Iniciar Sesión">
//       <RootStyle>

//         {/* {mdUp && (
//           <SectionStyle>
//             <Typography variant="h3" sx={{ px: 5, mt: 10, ml:4, mb:5 }}>
//               Bienvenido a SIGEM
//               <Typography sx={{ color: 'text.secondary' }}>Sistema gestor de documentos Municipal</Typography>
//             </Typography>
//             <Image
//               alt="login"
//               src="/logo/EscudoVerticalSinLetras.png"
//             />
//           </SectionStyle>
//         )} */}

//         <Container maxWidth="sm">
//           <ContentStyle>
//             <Card sx={[{"padding":10}]}>
//             <Typography variant="h3" textAlign={'center'} sx={{   mb:5 }}>
//               Bienvenido a SITICK
//               <Typography sx={{ color: 'text.secondary' }}>Sistema gestor de documentos Municipal</Typography>
//             </Typography>
//               <Alert severity="info" sx={{ mb: 3 }}>
//                 usuario: <strong>jperez</strong> / Contraseña:<strong> 17854</strong>
//               </Alert>
//               <LoginForm />

//             </Card>
//           </ContentStyle>
//         </Container>
//       </RootStyle>
//     </Page>
//   );
// }
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundImage: `url('/logo/ticket.webp')`, 
  backgroundSize: 'cover',
  padding: theme.spacing(3),
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  width: '100%',
  padding: theme.spacing(4),
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  color: theme.palette.primary.main,
}));

export default function Login() {
  return (
    <Page title="Iniciar Sesión">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <HeaderText variant="h4">
              Bienvenido a SITICK
            </HeaderText>
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mb: 3 }}>
              Sistema de ticket Municipal
            </Typography>
            <Alert severity="info" sx={{ mb: 3 }}>
              Usuario: <strong>jperez</strong> / Contraseña: <strong>17854</strong>
            </Alert>
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}